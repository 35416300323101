import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { HttpService } from '../services/http/http.service'

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  public baseUrl: any = '/api/apiv1/'

  public chengesMade: any = false
  public complianceDocId: any = ''

  constructor(private httpService: HttpService, private http: HttpClient) {}

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.chengesMade) {
      return component.canDeactivate()
        ? true
        : // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
          // when navigating away from your angular app, the browser will show a generic warning message
          // see http://stackoverflow.com/a/42207299/7307355
          // confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.')
          this.confirm()
    } else {
      this.runService()
      return true
    }
  }

  confirm() {
    const cnf = confirm(
      'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.',
    )
    if (cnf == true) {
      this.runService()
    }
    return cnf
  }

  runService() {
    const token = localStorage.getItem('tokenID')
    const playingRole = localStorage.getItem('playingRole')
    let options
    if (token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${token}`)
        .set('Role', `${playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    const data = this.http.put(
      this.baseUrl + 'compliance/release_edit_lock?doc_id=' + this.complianceDocId,
      {},
      {
        headers: options,
      },
    )

    data.subscribe(data1 => {})
  }
}
